<template lang="pug">
    #imageModal
        ul
            li(@click="setImage(contents.content)")
                .img
                    img(:src="image").image
                p(v-if="contents.content.text !== ''" v-html="contents.content.text")
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-image-modal",
    props,
    methods: {
        setImage(item) {
            this.$store.commit('setImage', { ativo: true, content: item })
        }
    },
    computed: {
        image() {
            return `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${this.contents.content.image}`
        }
    }
}
</script>

<style lang="stylus" scoped src="./ImageModal.styl"></style>